
// CONFERENCE CENTER FACILITIES

.cc-facility-fieldhouse {
  background-color: rgba(0,0,0,.2);
  background: url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-facility-mcaffee-fieldhouse-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-facility-fieldhouse {
    background-color: url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-facility-mcaffee-fieldhouse-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.cc-facility-auditorium {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-facility-auditorium-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-facility-auditorium {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.3)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-facility-auditorium-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.cc-facility-commons {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-cc-facility-commons-2-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-facility-commons {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-cc-facility-commons-1-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.cc-facility-dining-hall {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/images/foundation5-template-images/hero/cc-dining.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-facility-dining-hall {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2016/04/18/cc-dining-hall-750.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.cc-facility-meeting-room {
  background-color: rgba(0,0,0,.2);
  background: url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/13/cc-facility-forest-view-meeting-room-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-facility-meeting-room {
    background-color: rgba(0,0,0,.1);
    background: url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/13/cc-facility-forest-view-meeting-room-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.cc-facility-lodge-room-interior {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.2)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-facility-lakeside-lodge-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-facility-lodge-room-interior {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-facility-lakeside-lodge-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}
//PONDEROSA LODGE

.pl-facility-dining-hall {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-facility-dining-hall-1-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-facility-dining-hall {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-facility-dining-hall-1-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.pl-facility-cabin-exterior {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-facility-cabin-exterior-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-facility-cabin-exterior {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-facility-cabin-exterior-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.pl-facility-cabin-interior {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/20/summer-camp-pl-facility-cabin-interior-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-facility-cabin-interior {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/20/summer-camp-pl-facility-cabin-interior-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.pl-facility-game-room {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-facility-game-room-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-facility-game-room {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-facility-game-room-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0 70%;
    height:400px
  }
}

.pl-facility-forum {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.2)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/19/summer-camp-pl-facility-forum-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-facility-forum {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/19/summer-camp-pl-facility-forum-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    height:400px
  }
}

.pl-facility-lodge {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.2)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/03/16/m8a_9717-web.jpg") no-repeat center;
  background-size: cover;
  background-position: 0% 60%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-facility-lodge {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/03/16/m8a_9717-web.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}


//REDWOOD CAMP

.rc-facility-dining-hall {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-facility-dining-hall-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-facility-dining-hall {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),s
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-facility-dining-hall-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.rc-facility-victory-circle {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/19/summer-camp-rc-facilities-victory-circle-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-facility-victory-circle {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/19/summer-camp-rc-facilities-victory-circle-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.rc-facility-pavilion {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/20/summer-camp-rc-facility-pavilion-1-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-facility-pavilion {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-facility-pavilion-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    height:400px
  }
}

.rc-facility-cabin-interior {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2016/01/29/pl-cabin-2000.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-facility-cabin-interior {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2016/04/19/pl-cabin-750.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.rc-facility-cabin-exterior {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/19/summer-camp-rc-facilities-cabin-exterior-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-facility-cabin-exterior {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/19/summer-camp-rc-facilities-cabin-exterior-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}
