$bar-graph-height: 425px;

.bar-graph {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: $bar-graph-height;
  margin: 0;

  li {
    display: block;
    padding: rem-calc(25) 0;
    position: relative;
    text-align: center;
    vertical-align: bottom;
    border-radius: 4px 4px 0 0;
    max-width: 20%;
    height: 100%;
    margin: 0 1.8% 0 0;
    flex: 1 1 15%;
  }

  .bar-graph-axis {
    flex: 1 1 8%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bar-graph-label {
    margin: 0;
    background-color: none;
    color: $dark-gray;
    position: relative;

    @include breakpoint(medium) {
      &:before,
      &:after {
        content: "";
        position: absolute;
        border-bottom: 1px dashed $dark-gray;
        top: 0;
        left: 0;
        height: 50%;
        width: 20%;

        @include breakpoint(large) {
          width: 30%;
        }
      }

      &:after {
        left: auto;
        right: 0;
      }
    }
  }

  .percent {
    letter-spacing: -3px;
    opacity: 0.4;
    width: 100%;
    font-size: rem-calc(30);
    position: absolute;

    @include breakpoint(medium) {
      font-size: rem-calc(62);
    }

    span {
      font-size: rem-calc(30);
    }
  }

  .description {
    font-weight: 800;
    opacity: 0.5;
    text-transform: uppercase;
    width: 100%;
    font-size: 14px;
    bottom: 20px;
    position: absolute;
    font-size: rem-calc(16);
    overflow: hidden;
  }

 .bar {
    @each $name, $color in $foundation-palette {
      &.#{$name} {
        border: 1px solid $color;
        background: linear-gradient(lighten($color, 10%), $color 70%);
      }
    }
  }
}
