.promo {
  position: relative;
  overflow: hidden;

  #video_bg {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 0;
  }

  .row {
    min-height: 70vh;
  }

  //Change the row min-height if you don't want it to be 100% of the viewport, and adjust the message styles as needed.

  #message {
    color: #fff;
    text-transform: uppercase;
    z-index: 1;
    background: none;
  }
}
