.todo-list-card {
  background-color: $white;
  border-radius: $global-radius;
  max-width: 300px;

  .card-divider {
    border-bottom: 2px solid $medium-gray;
    background: inherit;
    display: flex;
    justify-content: space-between;
    h3 {
      margin-bottom: 0;
    }
    .close-button {
      position: inherit;
    }
  }


  ul {
    list-style-type: none;
    margin: 0;
    li {
      background-color: $light-gray;
      margin: 10px 0;
      padding: 10px;
    }
    input[type="checkbox"] {
      position: absolute;
      visibility: hidden;
      left: -99999px;
    }
    input[type="checkbox"] ~ label{
      position: relative;
      margin-left: 2rem;
    }
    input[type="checkbox"] ~ label::after {
      background: $white;
      border: 1px solid $light-gray;
      content: '';
      display: block;
      height: 20px;
      left: -2rem;
      width: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-80%);
    }
    // Active state
    input[type="checkbox"]:checked ~ label:after {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 18px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f00c";
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
}
