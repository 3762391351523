

.responsive-hamburger-icon {

  @include hamburger($white, $primary-color, 40px, 30px, 2px, 3);

  // Only affects medium screens and larger
  @include breakpoint(medium) {
    @include hamburger($primary-color, $dark-gray, 40px, 30px, 2px, 3);
  }
}

.hamburger-icon {
  @include hamburger($primary-color, $dark-gray, 40px, 30px, 2px, 3);
}


//.menu-icon::after {
//  position: absolute;
//  top: 0;
//  left: 0;
//  display: block;
//  width: 100%;
//  height: 2px;
//  background: $primary-color;
//  box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
//  content: '';
//}

//Experiment
.menu-icon::after {

//Small
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: $primary-color;
  box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
  content: '';

//Medium
  @include breakpoint(medium) {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: $primary-color;
    box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
    content: '';
  }
}
