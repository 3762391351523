.block-dark {
  color:#303030;
  text-align:center;
  background:$dark-gray;
}

.block-light {
  color:#303030;
  text-align:center;
  background:$light-gray;
}

.block-light p, .block-dark p {
  padding:0;
}

.block-grid.mobile > li {
  float:left;
}

.block-light hr, .block-dark hr {
  border-top-color: rgb(255, 255, 255);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
