@mixin circle-graph-circle($size) {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - #{$size/2});
  top: calc(50% - #{$size/2});
  width: $size;
  height: $size;
}

$circle-graph-size: rem-calc(180);
$circle-graph-percent-number-size: rem-calc(24);
$circle-graph-percent-unit-size: rem-calc(16);
$circle-graph-background-color: $light-gray;
$circle-graph-empty-color: $dark-gray;
$circle-graph-fill-color: $primary-color;

.circle-graph {
  width: $circle-graph-size;
  height: $circle-graph-size;
  border-radius: 50%;
  background-color: $circle-graph-empty-color;
  position: relative;

  &.gt-50 {
    background-color: $circle-graph-fill-color;
  }
}

.circle-graph-progress {
  @include circle-graph-circle($circle-graph-size);
  clip: rect(0, $circle-graph-size, $circle-graph-size, #{$circle-graph-size/2});

  .circle-graph-progress-fill {
    @include circle-graph-circle($circle-graph-size);
    clip: rect(0, #{$circle-graph-size/2}, $circle-graph-size, 0);
    background: $circle-graph-fill-color;
    transform: rotate(60deg);
  }

  .gt-50 & {
    clip: rect(0, #{$circle-graph-size/2}, $circle-graph-size, 0);

    .circle-graph-progress-fill {
      clip: rect(0, $circle-graph-size, $circle-graph-size, #{$circle-graph-size/2});
      background: $circle-graph-empty-color;
    }
  }
}
.circle-graph-percents {
  @include circle-graph-circle(#{$circle-graph-size/1.45});
  background: $circle-graph-background-color;
  text-align: center;
  display: table;
  z-index: 4;

  .circle-graph-percents-number {
    display: block;
    font-size: $circle-graph-percent-number-size;
    font-weight: bold;
    color: $circle-graph-fill-color;
  }

  .circle-graph-percents-units {
    display: block;
    font-size: $circle-graph-percent-unit-size;
    font-weight: bold;
  }
}
.circle-graph-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: 0;

  span {
    line-height: 1;
  }
}
