
// CONFERENCE CENTER ACTIVITIES

.cc-activity-saa {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-cc-activity-sequoia-2-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-activity-saa {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-cc-activity-sequoia-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.cc-rct-activity {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-cc-activity-sequoia-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-rct-activity {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-cc-activity-sequoia-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 70%;
    height:400px
  }
}

.cc-activity-pool {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-activity-pool-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: 0 20%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-activity-pool {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-activity-pool-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 20%;
    height:400px
  }
}

.cc-activity-fieldhouse {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-activity-fieldhouse-1-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: 0% 55%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-activity-fieldhouse {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-activity-fieldhouse-1-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.cc-activity-archery {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-activity-archery-3-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-activity-archery {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-cc-activity-archery-3-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.cc-activity-hiking {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/10/cc-activity-hiking-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .cc-activity-hiking {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/10/cc-activity-hiking-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

//PONDEROSA LODGE //

.pl-activity-pool {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-pl-activity-pool-2-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-pool {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-pl-activity-pool-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.pl-activity-aqua-climbing-wall {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-pl-activity-aqua-climbing-wall-pool-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-aqua-climbing-wall {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-pl-activity-aqua-climbing-wall-pool-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
    height:400px
  }
}

.pl-activity-climbing-wall {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-activity-climbing-wall-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-climbing-wall {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-pl-activity-climbing-wall-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
    height:400px
  }
}

.pl-activity-high-ropes {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-pl-activity-high-ropes-5-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-high-ropes {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-pl-activity-high-ropes-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.pl-activity-basketball-courts {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-pl-activity-basketball-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-basketball-courts {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-pl-activity-basketball-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.pl-activity-dodge-arrows {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-pl-activity-dodge-and-arrows-3-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: 0% 30%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-dodge-arrows {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-pl-activity-dodge-and-arrows-2-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
    height:400px
  }
}

.pl-activity-laser-tag {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/20/summer-camp-pl-activity-laser-tag-1-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: 0 10%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .pl-activity-laser-tag {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/20/summer-camp-pl-activity-laser-tag-1-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 60% 0%;
    height:400px
  }
}


//REDWOOD CAMP //

.rc-activity-pool {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-activity-redwood-camp-pool-2-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: 0% 10%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-activity-pool {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-activity-redwood-camp-pool-4-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.rc-activity-flying-squirrel {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/12/rc-activity-redwood-flying-squirrel-wide_zeLAjA4.jpg") no-repeat center;
  background-size: cover;
  background-position: 20% 5%;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-activity-flying-squirrel {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/12/rc-activity-redwood-flying-squirrel-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.rc-activity-boating {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-activity-redwood-camp-boats-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-activity-boating {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/18/summer-camp-rc-activity-redwood-camp-boats-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

.rc-activity-archery {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-rc-activity-redwood-camp-archery-1-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .rc-activity-archery {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/14/summer-camp-rc-activity-redwood-camp-archery-1-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 0% 45%;
    height:400px
  }
}

//KIDDER CREEK //

.kc-activity-adventure-ridge-high-ropes-course {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/21/summer-camp-kc-activity-adventure-ridge-high-ropes-course-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .kc-activity-adventure-ridge-high-ropes-course {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/21/summer-camp-kc-activity-adventure-ridge-high-ropes-course-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.kc-activity-horses {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/21/summer-camp-mount-hermon-kidder-creek-guest-groups-horseback-riding-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .kc-activity-horses {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/21/summer-camp-mount-hermon-kidder-creek-guest-groups-horseback-riding-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
    height:400px
  }
}

.kc-activity-pump-track {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-kc-activity-bike-pump-track-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .kc-activity-pump-track {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/24/summer-camp-kc-activity-bike-pump-track-tall.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
    height:400px
  }
}

.kc-activity-natural-waterfalls {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/21/summer-camp-kc-activity-natural-waterfalls-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .kc-activity-natural-waterfalls {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/21/summer-camp-kc-activity-natural-waterfalls-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}

.kc-activity-whitewater-rafting {
  background-color: rgba(0,0,0,.2);
  background: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/10/kc-activity-whitewater-rafting-wide.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media only screen and (max-width: 640px) {
  .kc-activity-whitewater-rafting {
    background-color: rgba(0,0,0,.1);
    background: linear-gradient(
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.2)
        ), url("https://media.mounthermon.org/ckeditor/ckeditor/upload/2018/12/10/kc-activity-whitewater-rafting-tall.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    height:400px
  }
}
