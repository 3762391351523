/*.callout {
  &::after {
    content:'';
    @include css-triangle(1rem, $secondary-color, down);
    position: absolute;
    bottom: -1rem;
    left: 5%;
  }
}*/


.hamburger-icon {
  @include hamburger($white, $primary-color, 20px, 16px, 1px, 3);
}
