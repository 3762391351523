.product-card {
  background-color: $white;
  border: 1px solid $light-gray;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.product-card-thumbnail {
  display: block;
  position: relative;
}

.product-card-title {
  font-family: 'Open sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.45rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.product-card-desc {
  color: $dark-gray;
  display: block;
  font-family: 'Open sans', sans-serif;
  font-size: 0.85rem;
}

.product-card-price {
  color: darken($dark-gray,30%);
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 600;
  margin-top: 0.8rem;
}

.product-card-sale {
  color: $medium-gray;
  display: inline-block;
  font-size: 0.85rem;
  margin-left: 0.3rem;
  text-decoration: line-through;
}

.product-card-colors {
  display: block;
  margin-top: 0.8rem;
}

.product-card-color-option {
  display: inline-block;
  height: 25px;
  width: 25px;
}
