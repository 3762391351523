.searchbar {
  margin: 1rem;
}

.search-field {
  width: 0;
  height: 40px;
  margin-left: 1rem;
  padding: 0;
  border-radius: 50px;
  border: none;
  transition: all 0.5s ease;
}

.expand-search {
  width: 80%;
  max-width: calc(80% - 3rem);
  border: 1px solid #c9c9c9;
  padding: .5rem;
}

svg {
  width: 20px;
  height: 20px;
}

.button {
  border-radius: 50px;
}
